<template>
  <el-row
    class="Card_Container list flex-column u_w--100p"
    type="flex"
    justify="center"
    align="center"
  >
    <el-col>
      <div class="header__text u_t--center flex flex-row">
        <span class="span-bottom">{{ $t('ui_bank_card_management') }}</span>
      </div>
      <!-- <div class=" bg--border  " /> -->
      <div class="container_gray">
        <div class="list-desc__title">
          <p class="u_m--l20">{{ $t('ui_hint_card3') }}</p>
        </div>
      </div>
      <div v-for="item in cardList" :key="item.id" class="flex flex-column flex-center u_p--b10">
        <div class="card_size flex flex-start flex-column">
          <div class="flex flex-justify-between card_line flex-row u_w--100p">
            <div class="u_font--w700 flex flex-justify-between flex-row">
              <div class="u_size--24 u_c--pointer">
                <!-- <img :src="`../../../../../Static/image/usermenu/bank.svg`" class="" alt="" > -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  height="24"
                  viewBox="0 0  24 24"
                  width="24"
                >
                  <g><rect fill="none" height="24" width="24" /></g>
                  <g>
                    <g class="bank_fff">
                      <rect height="7" width="3" x="4" y="10" />
                      <rect height="7" width="3" x="10.5" y="10" />
                      <rect height="3" width="20" x="2" y="19" />
                      <rect height="7" width="3" x="17" y="10" />
                      <polygon points="12,1 2,6 2,8 22,8 22,6" />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="">
                {{ item.bindingBank }}
              </div>
            </div>
            <span
              @click="toggleModal(true, item)"
              v-if="isShowDel"
              class="u_c--pointer u_underline"
            >
              <!-- <icon name="trash"></icon> {{ $t('btn_del') }}-->
              <i class="el-icon-delete"></i>
            </span>
          </div>
          <div class="card_line u_m--t5">
            <!-- {{ $t('rp_binding_bank') }}&nbsp;:&nbsp; -->
            <div class=" ">{{ item.accountName }}</div>
          </div>
          <div class="card_line">
            <div class="">{{ item.accountNumber }}</div>
          </div>
          <div class="card_line">
            {{ $t('ui_binding_time') }}&nbsp;:&nbsp;
            <div class="">{{ item.bindingTime.substr(5, 11) }}</div>
          </div>
          <div class="flex flex-justify-start card_line"></div>
        </div>
      </div>
      <div class="flex flex-column flex-center u_p--b10">
        <div
          :class="[
            'card_size flex flex-justify-center u_c--pointer flex-column flex-center',
            {
              'none-image': !checkSize
            }
          ]"
        >
          <button
            type="button"
            class="flex-column flex u_c--pointer btn--size"
            :class="['', {'btn--disabled': checkSize}]"
            @click="direct"
            v-if="!checkSize"
          >
            <!--     :disabled="checkSize" -->
            <span class="addcard u_font--w700 card_line--center">{{
              '+' + $t('btn_add_bank_card')
            }}</span>
          </button>
          <button
            type="button"
            class="flex-column flex u_c--pointer btn--size"
            :class="['', {'btn--disabled': checkSize}]"
            v-if="checkSize"
          >
            <!--     :disabled="checkSize" -->
            <span class="card_line u_font--w700 card_line--center">{{
              $t('tip_bind_card_limit')
            }}</span>
          </button>
        </div>
      </div>
      <div class="u_t--center"></div>
      <el-dialog
        v-model="ListModal"
        :show-close="false"
        center
        :close-on-click-modal="false"
        width="600px"
        custom-class="c_dialog"
      >
        <template #title>
          <span class="el-dialog__title">{{ $t('rp_del') }}</span>
          <button type="button" @click="toggleModal(false)" class="el-dialog__headerbtn">
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </template>
        <div class="list-detail__content u_p--l20 u_p--r20 u_p--t20">
          <form class="list-detail__form list-detail__form--center">
            <div class="u_p--b10 u_t--center">
              {{ $t(tipMsg) }}
            </div>
            <div class="form__field form__field--center" v-if="!step">
              <div class="form__title">
                {{ data.accountNumber }}
              </div>
            </div>
            <div class="list-detail__form list-detail__form--center" v-if="step === 1">
              <div class="form__field form__field--center">
                <div class="form__title">
                  <!--phone-->
                  <div class="form__field">
                    <div class="form__title">
                      {{ $t('ui_mobile_phone_number') }}
                    </div>
                    <div v-if="userInfo.phoneNumber.verification" class="u_t--left">
                      {{ userInfo.phoneNumber.text }}
                    </div>
                    <tel-input
                      v-else
                      ref="tel"
                      :preferredCountries="['VN', 'TH']"
                      @validate="() => {}"
                      class="j_background--day-t5"
                    ></tel-input>
                    <!--  -->
                    <div class="u_p--l5">
                      <el-button
                        round
                        class="userbtn-bg--submit c_button--normal"
                        icon=""
                        :type="'info'"
                        size="small"
                        :hairline="true"
                        @click="checkSMS"
                        v-text="(info.verPhoneWaitTime || $t('ui_send_verification')) + ''"
                        :disabled="+info.verPhoneWaitTime > +0"
                      ></el-button>
                    </div>
                  </div>
                  <!--phone code-->
                  <div class="form__field" :class="{'is-error': errorsMsg}">
                    <div class="form__title">
                      {{ $t('ui_sms_verification_code') }}
                    </div>
                    <el-input
                      class="c_input--longest"
                      v-model="info.phoneCode"
                      clearable
                      type="text"
                      :placeholder="$t('ui_please_enter_verification_code')"
                    >
                    </el-input>
                    <div class="u_p--l10 j_txt--modify" v-if="errorsMsg">
                      {{ $t(errorsMsg) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <template #footer>
          <button
            type="button"
            class="c_button--large btn-bg--primary btn-txt--primary"
            @click="onSuccess"
            :disabled="info.phoneCode.length !== 6"
          >
            <span class="c_button__text">{{ $t('btn_confirm') }}</span>
          </button>
        </template>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ElMessage, ElRow} from 'element-plus';
import {App} from '@/services';
import {userCard} from '@/services/server/wap/bank';
import {GetUser} from '@/model/tokenGetUser';
import {GetCards, ICard, IMessage} from '@/model/bank';
import {phoneVerification} from '@/services/server/wap/phone-verification';
import TelInput from '@/common/wap/telInput/index.vue';

@Options({
  components: {
    TelInput,
    ElRow
  }
})
export default class PersonalInformation extends Vue {
  userInfo = App.getUserinfo();
  cardList: ICard[] = [];
  info: {
    phoneNumber: string;
    phoneCode: string;
    cardId: string;
    showVerification: boolean;
    verPhoneWaitTime: number;
    phoneWaitTime: number;
    phoneErrorWaitTime: number;
    smsInterval: any;
  } = {
    phoneNumber: '',
    phoneCode: '',
    cardId: '',
    showVerification: false,
    verPhoneWaitTime: 0,
    phoneWaitTime: 300,
    phoneErrorWaitTime: 15,
    smsInterval: null
  };

  ListModal = false;
  step = 0;
  tipMsg = '';
  errorsMsg = '';

  get allowAdd() {
    return this.cardList && this.cardList.length < 3;
  }
  get phoneNumberVerification() {
    return this.userInfo && this.userInfo.phoneNumber.verification;
  }
  get checkSize() {
    return this.cardList && this.cardList.length >= 3;
  }
  get currentCard() {
    return this.cardList.find(value => value.cardId === this.info.cardId);
  }
  get isShowDel() {
    return this.cardList.length > 1;
  }
  get isShowCardTime() {
    return true;
    // return +this.bindingCardTime !== 0;
  }
  get cardTimeText() {
    return this.$t('ui_hint_card_time8').replace('8', '0');
  }

  async mounted() {
    if (!this.userInfo) {
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    const cardsInfo = await GetCards.read();
    if (!(cardsInfo instanceof Error)) {
      this.cardList = cardsInfo.list;
    }
  }

  direct() {
    this.$router.push({name: 'AddCard'});
  }

  async checkSMS() {
    if (this.userInfo?.phoneNumber.verification) {
      this.showWaitTime();
      //已認證
      const data = await phoneVerification.read<IMessage>({});
      console.log('checkSMS data: ', data);
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message: data.message || 'fail'
        });
      } else {
        ElMessage({
          type: 'success',
          message: data.message || 'success'
        });
      }
      return data;
    } else {
      //未認證
      const data = await this.addSMS(this.info.phoneNumber);
      return data;
    }
  }

  async addSMS(phone: string) {
    !phone && (phone = this.info.phoneNumber);
    this.showWaitTime();
    return await phoneVerification
      .created({
        /* eslint-disable-next-line */
        phone_number: phone
      })
      .then((response: any) => {
        console.log('response: ', response);
        // this.$ElMessage.success(data.message)
        ElMessage({
          type: 'success',
          message: response?.message || 'success'
        });
      })
      .catch(error => {
        // this.$ElMessage.fail(error.message)
        ElMessage({
          type: 'error',
          message: error.message || 'fail'
        });
        this.info.verPhoneWaitTime = this.info.phoneErrorWaitTime;
        return error;
      });
  }

  showWaitTime() {
    this.info.verPhoneWaitTime = this.info.phoneWaitTime;
    this.info.smsInterval = setInterval(() => {
      this.info.verPhoneWaitTime = +this.info.verPhoneWaitTime - 1;
      if (+this.info.verPhoneWaitTime === 0) {
        clearInterval(this.info.smsInterval);
      }
    }, 1000);
  }
  onCancel() {
    this.info.showVerification = false;
    this.info.verPhoneWaitTime = 0;
    clearInterval(this.info.smsInterval);
  }
  addCardClicked() {
    this.$router.push({name: 'AddBankCard'});
  }
  toggleModal(flag: boolean, item: any) {
    console.log('item: ', item);
    this.errorsMsg = '';
    this.info.phoneCode = '';
    this.ListModal = flag;
    this.step = 1;
    // this.data = item;
    // 关闭弹窗时，清除倒数
    this.info.cardId = item.cardId;
    this.info.verPhoneWaitTime = 0;
    clearInterval(this.info.smsInterval);
  }
  onDelete(card: {accountNumber: string; cardId: string}) {
    //手機驗證
    this.info.phoneCode = ''; //tmp
    if (!this.userInfo?.phoneNumber.verification) {
      ElMessage.error(this.$t('ui_you_have_not_verified_your_phone_or_email'));
      return;
    }
    // const options = {
    //   title: this.$t('ui_del_bank_card'),
    //   message: `${this.$t('ui_bank_acc_colon')}${card.accountNumber}`,
    //   closeOnClickOverlay: true,
    //   confirmButtonColor: '#1F55F0'
    // };
    // Dialog.confirm(options).then(action => {
    //   if (action === 'confirm') {
    this.info.cardId = card.cardId;
    this.info.showVerification = true;
    //   }
    // });
    // confirmMessageBox(options);
    return;
  }
  async onSuccess() {
    // console.log(`debug: onSuccess -> str`, str)
    const para = {
      // eslint-disable-next-line
      card_id: this.info.cardId,
      // eslint-disable-next-line
      phone_number_code: this.info.phoneCode
    };
    // const data = await
    userCard
      .deleted<IMessage>(para)
      .then(async (data: IMessage) => {
        ElMessage.success(data.message || 'success');
        const cardsInfo = await GetCards.read();
        if (!(cardsInfo instanceof Error)) {
          this.cardList = cardsInfo.list;
          this.info.showVerification = false;
          this.ListModal = false;
        }
      })
      .catch(() => {
        ElMessage.error(this.$t('ui_verification_code_error'));
      });
  }
}
</script>
<style lang="scss" scoped>
.password-container {
  font-size: 16px;
  font-weight: 400;
}
.form__field {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  .form__title {
    padding-top: 5px;
    text-align: end;
    min-width: 100px;
  }
}
.c_dialog {
  .form__field {
    justify-content: space-around;
  }
}
.c_input--longest {
  width: 100%;
  min-width: 270px;
}

.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.c_input--longest .el-input__inner {
  background-color: #f5f5f5;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
[class*='c_input'] {
  border-radius: 5px;
}
[class*='c_input'] button,
[class*='c_input'] > div,
[class*='c_input'] input,
[class*='c_input'] select,
[class*='c_input'] textarea,
[class*='c_input'] .c_input__inner {
  border-radius: inherit;
  width: inherit;
}
[class*='c_button']:not(.c_button__text)[class*='--huge'] {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
  min-width: 120px;
}
[class*='c_button']:not(.c_button__text) {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font: 14px 'Open Sans', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  &[disabled] {
    cursor: not-allowed;
    background-color: #e8e8e8;
    color: #999999;
  }
}
.userbtn-bg--submit {
  background-color: #1f55f0;
  color: #ffffff;
}
.u_w--100p {
  width: 100%;
}
.u_m--t20 {
  margin-top: 20px;
}
.Card_Container {
  width: 100%;
  border-radius: 0 5px 5px 0;
  box-sizing: border-box;
  background-color: $cf;
}
.header__text {
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 47px;
  font-weight: 700;
  @extend .flex-justify-center;
}
.list-desc__title {
  max-width: 100%;
  display: inline-block;
  line-height: 160%;
  padding: 7px 0;
  vertical-align: top;
}
.u_m--10 {
  padding: 10px;
}
.flex_between {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}
.u_size--24 {
  height: 0.24rem;
  height: 0.24rem;
  padding-right: 0.1rem;
}

.Mine__title {
  color: #000;
}
.card_size {
  width: 100%;
  color: #000;
  // border: #bf9000 1px solid;
  border-radius: 0.5em;
  padding: 18px;
  margin: 10px 0 0 10px;
  box-shadow: 2px 2px 5px #0a286494;
  background-image: url('~@/assets/web/image/usermenu/card.png'),
    linear-gradient(270deg, #6572eb 0%, #6152d9 100%);
  height: 147px;
  box-shadow: 0px 4px 10px 0px rgba(97, 82, 217, 0.3);
  border-radius: 8px;
  background-size: contain, 100%;
  background-repeat: no-repeat, repeat;
  background-position: right, center;
  min-width: 220px;
  &.none-image {
    background-color: #e8eefd;
    background-image: none !important;
  }
}
.card_size .c_button__text {
  font-size: 1em;
}
.card_line {
  display: flex;
  font-size: 12px;
  line-height: 28px;
  color: $cf;
}
.addcard {
  display: flex;
  font-size: 16px;
  color: $bg;
}
.btn--size {
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  font-weight: 700;
}
.card_line--center {
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}
[class*='c_button']:not(.c_button__text)[class*='--disabled'] {
  color: gray;
}
.u_underline {
  text-decoration: underline;
}
.bg--border {
  background-color: #c4c4c4;
  height: 2px;
  margin: 2vh 0;
}
.btn--disabled {
  color: gray;
  cursor: not-allowed;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.container_gray {
  margin: 0 0 0 5%;
}
.el-dialog {
  width: 35%;
  z-index: 3000;
}
.btn-bg--primary {
  background-color: $bg;
}
.btn-txt--primary {
  color: $cf;
}
:deep(.c_button--large) {
  width: calc(100% - 220px);
  margin: auto auto auto 45px;
  &:disabled {
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    color: #999;
    cursor: not-allowed;
  }
}
.u_p--l5 :deep(.el-button.is-disabled) {
  padding: 9px 20px;
}
.bank_fff {
  fill: $cf;
}
.u_p--b10 {
  padding-bottom: 10px;
}
.u_c--pointer {
  cursor: pointer;
}
.u_underline {
  text-decoration: underline;
}
.u_size--24 {
  font-size: 24px;
}
</style>
