
import {Options, Vue} from 'vue-class-component';
import {ElMessage, ElRow} from 'element-plus';
import {App} from '@/services';
import {userCard} from '@/services/server/wap/bank';
import {GetUser} from '@/model/tokenGetUser';
import {GetCards, ICard, IMessage} from '@/model/bank';
import {phoneVerification} from '@/services/server/wap/phone-verification';
import TelInput from '@/common/wap/telInput/index.vue';

@Options({
  components: {
    TelInput,
    ElRow
  }
})
export default class PersonalInformation extends Vue {
  userInfo = App.getUserinfo();
  cardList: ICard[] = [];
  info: {
    phoneNumber: string;
    phoneCode: string;
    cardId: string;
    showVerification: boolean;
    verPhoneWaitTime: number;
    phoneWaitTime: number;
    phoneErrorWaitTime: number;
    smsInterval: any;
  } = {
    phoneNumber: '',
    phoneCode: '',
    cardId: '',
    showVerification: false,
    verPhoneWaitTime: 0,
    phoneWaitTime: 300,
    phoneErrorWaitTime: 15,
    smsInterval: null
  };

  ListModal = false;
  step = 0;
  tipMsg = '';
  errorsMsg = '';

  get allowAdd() {
    return this.cardList && this.cardList.length < 3;
  }
  get phoneNumberVerification() {
    return this.userInfo && this.userInfo.phoneNumber.verification;
  }
  get checkSize() {
    return this.cardList && this.cardList.length >= 3;
  }
  get currentCard() {
    return this.cardList.find(value => value.cardId === this.info.cardId);
  }
  get isShowDel() {
    return this.cardList.length > 1;
  }
  get isShowCardTime() {
    return true;
    // return +this.bindingCardTime !== 0;
  }
  get cardTimeText() {
    return this.$t('ui_hint_card_time8').replace('8', '0');
  }

  async mounted() {
    if (!this.userInfo) {
      await GetUser.action();
      this.userInfo = App.getUserinfo();
    }
    const cardsInfo = await GetCards.read();
    if (!(cardsInfo instanceof Error)) {
      this.cardList = cardsInfo.list;
    }
  }

  direct() {
    this.$router.push({name: 'AddCard'});
  }

  async checkSMS() {
    if (this.userInfo?.phoneNumber.verification) {
      this.showWaitTime();
      //已認證
      const data = await phoneVerification.read<IMessage>({});
      console.log('checkSMS data: ', data);
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message: data.message || 'fail'
        });
      } else {
        ElMessage({
          type: 'success',
          message: data.message || 'success'
        });
      }
      return data;
    } else {
      //未認證
      const data = await this.addSMS(this.info.phoneNumber);
      return data;
    }
  }

  async addSMS(phone: string) {
    !phone && (phone = this.info.phoneNumber);
    this.showWaitTime();
    return await phoneVerification
      .created({
        /* eslint-disable-next-line */
        phone_number: phone
      })
      .then((response: any) => {
        console.log('response: ', response);
        // this.$ElMessage.success(data.message)
        ElMessage({
          type: 'success',
          message: response?.message || 'success'
        });
      })
      .catch(error => {
        // this.$ElMessage.fail(error.message)
        ElMessage({
          type: 'error',
          message: error.message || 'fail'
        });
        this.info.verPhoneWaitTime = this.info.phoneErrorWaitTime;
        return error;
      });
  }

  showWaitTime() {
    this.info.verPhoneWaitTime = this.info.phoneWaitTime;
    this.info.smsInterval = setInterval(() => {
      this.info.verPhoneWaitTime = +this.info.verPhoneWaitTime - 1;
      if (+this.info.verPhoneWaitTime === 0) {
        clearInterval(this.info.smsInterval);
      }
    }, 1000);
  }
  onCancel() {
    this.info.showVerification = false;
    this.info.verPhoneWaitTime = 0;
    clearInterval(this.info.smsInterval);
  }
  addCardClicked() {
    this.$router.push({name: 'AddBankCard'});
  }
  toggleModal(flag: boolean, item: any) {
    console.log('item: ', item);
    this.errorsMsg = '';
    this.info.phoneCode = '';
    this.ListModal = flag;
    this.step = 1;
    // this.data = item;
    // 关闭弹窗时，清除倒数
    this.info.cardId = item.cardId;
    this.info.verPhoneWaitTime = 0;
    clearInterval(this.info.smsInterval);
  }
  onDelete(card: {accountNumber: string; cardId: string}) {
    //手機驗證
    this.info.phoneCode = ''; //tmp
    if (!this.userInfo?.phoneNumber.verification) {
      ElMessage.error(this.$t('ui_you_have_not_verified_your_phone_or_email'));
      return;
    }
    // const options = {
    //   title: this.$t('ui_del_bank_card'),
    //   message: `${this.$t('ui_bank_acc_colon')}${card.accountNumber}`,
    //   closeOnClickOverlay: true,
    //   confirmButtonColor: '#1F55F0'
    // };
    // Dialog.confirm(options).then(action => {
    //   if (action === 'confirm') {
    this.info.cardId = card.cardId;
    this.info.showVerification = true;
    //   }
    // });
    // confirmMessageBox(options);
    return;
  }
  async onSuccess() {
    // console.log(`debug: onSuccess -> str`, str)
    const para = {
      // eslint-disable-next-line
      card_id: this.info.cardId,
      // eslint-disable-next-line
      phone_number_code: this.info.phoneCode
    };
    // const data = await
    userCard
      .deleted<IMessage>(para)
      .then(async (data: IMessage) => {
        ElMessage.success(data.message || 'success');
        const cardsInfo = await GetCards.read();
        if (!(cardsInfo instanceof Error)) {
          this.cardList = cardsInfo.list;
          this.info.showVerification = false;
          this.ListModal = false;
        }
      })
      .catch(() => {
        ElMessage.error(this.$t('ui_verification_code_error'));
      });
  }
}
